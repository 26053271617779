.App {
  text-align: center;
  font-size: x-large;
}

table {
  margin: 0 auto;
}

td {
  width: 70px;
  height: 70px;
  font-size: 1.2em;
}

table span {
  cursor: default;
}

td:hover {
  font-size: 1.7em;
}

